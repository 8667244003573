



































import { defineComponent, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';

export default defineComponent({
  name: 'SectionLens',
  props: {
    topHeight: Number
  },
  setup(props, { emit }) {
    const section_1: Ref<HTMLDivElement | null> = ref(null);
    const section_1_tmp: Ref<HTMLDivElement | null> = ref(null);
    const section_1_1: Ref<HTMLDivElement | null> = ref(null);

    const animationCheckHandler = () => {
      const elmSection_1 = section_1.value as HTMLElement;
      if (
        window.pageYOffset >
          elmSection_1.offsetHeight - elmSection_1.offsetHeight / 2 &&
        window.pageYOffset <
          elmSection_1.offsetHeight * 2 - elmSection_1.offsetHeight / 2
      ) {
        section_1.value!.className = 'cont cont1 active1';
      } else if (
        window.pageYOffset >
          elmSection_1.offsetHeight * 2 - elmSection_1.offsetHeight / 2 &&
        window.pageYOffset < elmSection_1.offsetHeight * 2
      ) {
        section_1.value!.className = 'cont cont1 active1 active1-1';
      }

      if (
        window.pageYOffset >
        elmSection_1.offsetHeight - elmSection_1.offsetHeight / 2
      ) {
        emit('emitIsScrollImageShow', true);
      } else {
        emit('emitIsScrollImageShow', false);
      }
    };

    const scrollEventHandler = () => {
      const elmSection_1 = section_1.value as HTMLElement;

      animationCheckHandler();

      if (
        window.pageYOffset > elmSection_1.offsetHeight &&
        window.pageYOffset < elmSection_1.offsetHeight * 2
      ) {
        (section_1_tmp.value as HTMLElement).style.display = 'block';
        (section_1_1.value as HTMLElement).style.visibility = 'hidden';
        elmSection_1.style.position = 'fixed';
        elmSection_1.style.top = '0';
      } else {
        (section_1_1.value as HTMLElement).style.visibility =
          window.pageYOffset > elmSection_1.offsetHeight * 2
            ? 'visible'
            : 'hidden';
        (section_1_tmp.value as HTMLElement).style.display = 'none';
        elmSection_1.style.position = '';
        elmSection_1.style.top = '';
      }
    };

    return {
      i18nTxt,
      section_1,
      section_1_1,
      section_1_tmp,
      scrollEventHandler
    };
  }
});

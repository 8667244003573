




































































import {
  defineComponent,
  onMounted,
  onUnmounted,
  Ref,
  ref
} from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';
import SectionDemo from '@/components/home/SectionDemo.vue';
import SectionLens from '@/components/home/SectionLens.vue';
import SectionMobile from '@/components/home/SectionMobile.vue';
import SectionPartner from '@/components/home/SectionPartner.vue';
import SectionTag from '@/components/home/SectionTag.vue';
import SectionTrend from '@/components/home/SectionTrend.vue';
import SectionTryOn from '@/components/home/SectionTryOn.vue';
import SectionWeave from '@/components/home/SectionWeave.vue';
import { isPC } from '@/utils/browser-state';

export default defineComponent({
  name: 'Home',
  components: {
    SectionLens,
    SectionWeave,
    SectionTryOn,
    SectionTag,
    SectionTrend,
    SectionPartner,
    SectionDemo,
    SectionMobile
  },
  setup(props, { emit }) {
    const isPageStart = ref(false);
    const topMain: Ref<HTMLDivElement | null> = ref(null);
    const secAILensRef: Ref<any | null> = ref(null);
    const secAIWeaveRef: Ref<any | null> = ref(null);
    const secAITryOnRef: Ref<any | null> = ref(null);
    const secAITagRef: Ref<any | null> = ref(null);
    const secTrendRef: Ref<any | null> = ref(null);
    const secPartnerRef: Ref<any | null> = ref(null);
    const secDemoRef: Ref<any | null> = ref(null);
    const secMobileRef: Ref<any | null> = ref(null);
    const section_m_tmp: Ref<HTMLDivElement | null> = ref(null);
    const topHeight = ref(topMain.value?.offsetHeight);
    const windowYOffset = ref(0);
    const mainSlogan = i18nTxt('home_slogan').split('|');
    const pageIndex = ref(1);
    const isPageShow = ref(false);
    const isScrollShow = ref(false);
    const isTextWhite = ref(false);
    const isPcCheck = isPC();

    const scrollEventHandler = () => {
      windowYOffset.value = window.pageYOffset;
      if (isPcCheck) {
        secAILensRef.value.scrollEventHandler();
        secAIWeaveRef.value.scrollEventHandler();
        secAITryOnRef.value.scrollEventHandler();
        secAITagRef.value.scrollEventHandler();
      } else {
        secMobileRef.value.scrollEventHandler();
      }
      secTrendRef.value.scrollEventHandler();
      secPartnerRef.value.scrollEventHandler();
      secDemoRef.value.scrollEventHandler();
    };

    const mobileTempSectionHandler = (isShow: boolean) => {
      section_m_tmp.value!.style.display = isShow ? 'block' : 'none';
    };

    const setMobileSectionHeight = (value: number) => {
      section_m_tmp.value!.style.height = `${value}px`;
      section_m_tmp.value!.style.position = 'relative';
    };

    const updatePaging = (res: { pageNum: number; isShow: boolean }) => {
      pageIndex.value = res.pageNum;
      isPageShow.value = res.isShow;
    };

    const scrollTextStyleChange = (value: boolean) => {
      isTextWhite.value = value;
    };

    const scrollImageShowCheck = (value: boolean) => {
      isScrollShow.value = value;
    };

    onMounted(() => {
      emit('emitIsHomeFocus', true);
      window.scrollTo(0, 0);
      window.addEventListener('scroll', scrollEventHandler);
      topHeight.value = topMain.value?.offsetHeight;
      setTimeout(() => {
        isPageStart.value = true;
      }, 100);
    });

    return {
      isPC,
      isPageStart,
      topMain,
      topHeight,
      secAILensRef,
      secAIWeaveRef,
      secAITryOnRef,
      secAITagRef,
      secTrendRef,
      secPartnerRef,
      secDemoRef,
      secMobileRef,
      section_m_tmp,
      windowYOffset,
      mainSlogan,
      pageIndex,
      isPageShow,
      isScrollShow,
      isPcCheck,
      isTextWhite,
      updatePaging,
      mobileTempSectionHandler,
      setMobileSectionHeight,
      scrollTextStyleChange,
      scrollImageShowCheck
    };
  }
});











































import { defineComponent, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';

export default defineComponent({
  name: 'SectionTag',
  props: {
    topHeight: Number
  },
  setup() {
    const section_4: Ref<HTMLDivElement | null> = ref(null);
    const section_4_1: Ref<HTMLDivElement | null> = ref(null);
    const section_4_tmp: Ref<HTMLDivElement | null> = ref(null);

    const animationCheckHandler = () => {
      const elmSection_4 = section_4.value as HTMLElement;
      if (
        window.pageYOffset >
          elmSection_4.offsetHeight * 7 - elmSection_4.offsetHeight / 2 &&
        window.pageYOffset <
          elmSection_4.offsetHeight * 8 - elmSection_4.offsetHeight / 2
      ) {
        section_4.value!.className = 'cont cont4 active4';
      } else if (
        window.pageYOffset >
          elmSection_4.offsetHeight * 8 - elmSection_4.offsetHeight / 2 &&
        window.pageYOffset < elmSection_4.offsetHeight * 8
      ) {
        section_4.value!.className = 'cont cont4 active4 active4-1';
      }
    };

    const scrollEventHandler = () => {
      const elmSection_4 = section_4.value as HTMLElement;
      animationCheckHandler();
      if (
        window.pageYOffset > elmSection_4.offsetHeight * 7 &&
        window.pageYOffset < elmSection_4.offsetHeight * 8
      ) {
        (section_4_tmp.value as HTMLElement).style.display = 'block';
        (section_4_1.value as HTMLElement).style.visibility = 'hidden';
        elmSection_4.style.position = 'fixed';
        elmSection_4.style.top = '0';
      } else {
        (section_4_1.value as HTMLElement).style.visibility =
          window.pageYOffset > elmSection_4.offsetHeight * 8
            ? 'visible'
            : 'hidden';
        (section_4_tmp.value as HTMLElement).style.display = 'none';
        elmSection_4.style.position = '';
        elmSection_4.style.top = '';
      }
    };

    return {
      i18nTxt,
      section_4,
      section_4_1,
      section_4_tmp,
      scrollEventHandler
    };
  }
});

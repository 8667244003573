













































































































































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';
import { isPC } from '@/utils/browser-state';

export default defineComponent({
  name: 'SectionMobile',
  props: {
    topHeight: Number
  },
  setup(props, { emit }) {
    const isPCCheck: boolean = isPC();
    const section_area: Ref<HTMLDivElement | null> = ref(null);
    const section_m1: Ref<HTMLDivElement | null> = ref(null);
    const section_m1_1: Ref<HTMLDivElement | null> = ref(null);
    const section_m2: Ref<HTMLDivElement | null> = ref(null);
    const section_m2_1: Ref<HTMLDivElement | null> = ref(null);
    const section_m3: Ref<HTMLDivElement | null> = ref(null);
    const section_m3_1: Ref<HTMLDivElement | null> = ref(null);
    const section_m4: Ref<HTMLDivElement | null> = ref(null);
    const section_m4_1: Ref<HTMLDivElement | null> = ref(null);
    const section_list = document.getElementsByClassName('cont_m');
    const headerElm = document.getElementById('header') as HTMLElement;
    const moveHeight = ref(0);
    const SCROLL_STATUS = {
      START: 'start',
      PAUSE: 'pause',
      RESUME: 'resume'
    };
    const stopPosition = ref(0);
    let scrollTimer: any = null;
    let isStopCheck = [
      SCROLL_STATUS.START,
      SCROLL_STATUS.START,
      SCROLL_STATUS.START,
      SCROLL_STATUS.START,
      SCROLL_STATUS.START,
      SCROLL_STATUS.START,
      SCROLL_STATUS.START,
      SCROLL_STATUS.START
    ];

    const sectionAnimationHandler = (index: number, isInit?: boolean) => {
      for (let i = 0; i < section_list.length; i++) {
        if (i <= index) continue;
        (section_list.item(i) as HTMLElement).style.transform = `translateY(-${
          isInit ? 0 : moveHeight.value * (index + 1)
        }px)`;
        (section_list.item(i) as HTMLElement).style.transition = `transform 1s`;
      }
      if (isStopCheck[index + 1] === SCROLL_STATUS.START && !isInit) {
        isStopCheck[index + 1] = SCROLL_STATUS.PAUSE;
        stopPosition.value = window.pageYOffset;
        isStopCheck = isStopCheck.map((value, idx) => {
          if (index + 1 !== idx) value = SCROLL_STATUS.START;
          return value;
        });
      }
    };

    const scrollStopAndGoHandler = (index: number) => {
      if (isStopCheck[index] === SCROLL_STATUS.PAUSE) {
        window.document.body.style.overflow = 'hidden';
      }
      scrollTimer = setTimeout(() => {
        isStopCheck[index] = SCROLL_STATUS.RESUME;
        window.document.body.style.overflow = '';
        clearTimeout(scrollTimer);
      }, 500);
    };

    const sectionAreaCheck = () => {
      // section 4
      if (
        window.pageYOffset >
        section_m1.value!.offsetHeight * 8 - section_m1.value!.offsetHeight / 2
      ) {
        section_m4_1.value!.className = 'cont cont4 cont_m active4 active4-1';
        sectionAnimationHandler(6);
        emit('emitUpdatePaging', { pageNum: 8, isShow: true });
        scrollStopAndGoHandler(7);
      } else if (window.pageYOffset > section_m1.value!.offsetHeight * 7) {
        sectionAnimationHandler(5);
        emit('emitUpdatePaging', { pageNum: 7, isShow: true });
        scrollStopAndGoHandler(6);
      } else if (
        // section 3
        window.pageYOffset >
        section_m1.value!.offsetHeight * 6
      ) {
        section_m3_1.value!.className = 'cont cont3 cont_m active3 active3-1';
        sectionAnimationHandler(4);
        emit('emitUpdatePaging', { pageNum: 6, isShow: true });
        scrollStopAndGoHandler(5);
      } else if (window.pageYOffset > section_m1.value!.offsetHeight * 5) {
        sectionAnimationHandler(3);
        emit('emitUpdatePaging', { pageNum: 5, isShow: true });
        scrollStopAndGoHandler(4);
      } else if (
        // section 2
        window.pageYOffset >
        section_m1.value!.offsetHeight * 4
      ) {
        section_m2_1.value!.className = 'cont cont2 cont_m active2 active2-1';
        sectionAnimationHandler(2);
        emit('emitUpdatePaging', { pageNum: 4, isShow: true });
        scrollStopAndGoHandler(3);
      } else if (window.pageYOffset > section_m1.value!.offsetHeight * 3) {
        sectionAnimationHandler(1);
        emit('emitUpdatePaging', { pageNum: 3, isShow: true });
        scrollStopAndGoHandler(2);
      } else if (
        // section 1
        window.pageYOffset >
        section_m1.value!.offsetHeight * 2
      ) {
        section_m1.value!.className = 'cont cont1 cont_m active1 active1-1';
        sectionAnimationHandler(0);
        emit('emitUpdatePaging', { pageNum: 2, isShow: true });
        scrollStopAndGoHandler(1);
      } else if (
        window.pageYOffset >
        section_m1.value!.offsetHeight - headerElm.offsetHeight
      ) {
        section_m1.value!.className = 'cont cont1 cont_m active1';
        sectionAnimationHandler(0, true);
        emit('emitUpdatePaging', { pageNum: 1, isShow: true });
      }
    };

    const scrollEventHandler = () => {
      const tmpSection = section_area.value as HTMLElement;
      emit('emitSetHeight', tmpSection.offsetHeight);
      if (
        window.pageYOffset >
          section_m1.value!.offsetHeight - headerElm.offsetHeight &&
        window.pageYOffset < tmpSection.offsetHeight
      ) {
        emit('emitIsShowBlock', true);
        tmpSection.style.position = 'fixed';
        tmpSection.style.top = `${headerElm.offsetHeight}px`;
        sectionAreaCheck();
      } else {
        emit('emitIsShowBlock', false);
        tmpSection.style.position = '';
        tmpSection.style.top = '';
        emit('emitUpdatePaging', { pageNum: 1, isShow: false });
        isStopCheck = isStopCheck.map((value) => {
          value = SCROLL_STATUS.START;
          return value;
        });
      }
    };

    onMounted(() => {
      section_m1.value!.style.height = '100vh';
      section_m1.value!.style.position = 'relative';
      section_m1_1.value!.style.height = '100vh';
      section_m1_1.value!.style.position = 'relative';

      section_m2.value!.style.height = '100vh';
      section_m2.value!.style.position = 'relative';
      section_m2_1.value!.style.height = '100vh';
      section_m2_1.value!.style.position = 'relative';

      section_m3.value!.style.height = '100vh';
      section_m3.value!.style.position = 'relative';
      section_m3_1.value!.style.height = '100vh';
      section_m3_1.value!.style.position = 'relative';

      section_m4.value!.style.height = '100vh';
      section_m4.value!.style.position = 'relative';
      section_m4_1.value!.style.height = '100vh';
      section_m4_1.value!.style.position = 'relative';

      moveHeight.value = section_m1.value!.offsetHeight;
    });

    return {
      i18nTxt,
      isPCCheck,
      section_area,
      section_m1,
      section_m1_1,
      section_m2,
      section_m2_1,
      section_m3,
      section_m3_1,
      section_m4,
      section_m4_1,
      scrollEventHandler
    };
  }
});

















import { defineComponent, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n';
import { useI18n } from '@/composables/useI18n';
import { ContactPages } from '@/router/modules/contact';
import { isPC } from '@/utils/browser-state';

export default defineComponent({
  name: 'SectionDemo',
  props: {
    topHeight: Number
  },
  setup(props, { emit }) {
    const { getLocale } = useI18n();
    const section_7: Ref<HTMLDivElement | null> = ref(null);
    const isPCCheck: boolean = isPC();

    const scrollEventHandler = () => {
      if (isPCCheck) {
        if (
          window.pageYOffset >
          props.topHeight! + section_7.value!.offsetHeight * 10 - 100
        ) {
          section_7.value!.className = 'cont cont_bottom visible';
        }
        if (
          window.pageYOffset >
          props.topHeight! +
            section_7.value!.offsetHeight * 10 -
            section_7.value!.offsetHeight / 2
        ) {
          emit('emitIsTextWhite', true);
        } else {
          emit('emitIsTextWhite', false);
        }
      } else {
        if (window.pageYOffset > props.topHeight! + window.innerHeight * 10) {
          section_7.value!.className = 'cont cont_bottom visible';
        }
      }
    };

    const contactHandler = () => {
      window.open(`${ContactPages.ticketPage}?lang=${getLocale()}`, '_blank');
    };

    return {
      i18nTxt,
      section_7,
      scrollEventHandler,
      contactHandler
    };
  }
});

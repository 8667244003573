








































import { defineComponent, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';

export default defineComponent({
  name: 'SectionWeave',
  props: {
    topHeight: Number
  },
  setup() {
    const section_2: Ref<HTMLDivElement | null> = ref(null);
    const section_2_1: Ref<HTMLDivElement | null> = ref(null);
    const section_2_tmp: Ref<HTMLDivElement | null> = ref(null);

    const animationCheckHandler = () => {
      const elmSection_2 = section_2.value as HTMLElement;
      if (
        window.pageYOffset >
          elmSection_2.offsetHeight * 3 - elmSection_2.offsetHeight / 2 &&
        window.pageYOffset <
          elmSection_2.offsetHeight * 4 - elmSection_2.offsetHeight / 2
      ) {
        section_2.value!.className = 'cont cont2 active2';
      } else if (
        window.pageYOffset >
          elmSection_2.offsetHeight * 4 - elmSection_2.offsetHeight / 2 &&
        window.pageYOffset < elmSection_2.offsetHeight * 4
      ) {
        section_2.value!.className = 'cont cont2 active2 active2-1';
      }
    };

    const scrollEventHandler = () => {
      const elmSection_2 = section_2.value as HTMLElement;
      animationCheckHandler();
      if (
        window.pageYOffset > elmSection_2.offsetHeight * 3 &&
        window.pageYOffset < elmSection_2.offsetHeight * 4
      ) {
        (section_2_tmp.value as HTMLElement).style.display = 'block';
        (section_2_1.value as HTMLElement).style.visibility = 'hidden';
        elmSection_2.style.position = 'fixed';
        elmSection_2.style.top = '0';
      } else {
        (section_2_1.value as HTMLElement).style.visibility =
          window.pageYOffset > elmSection_2.offsetHeight * 4
            ? 'visible'
            : 'hidden';
        (section_2_tmp.value as HTMLElement).style.display = 'none';
        elmSection_2.style.position = '';
        elmSection_2.style.top = '';
      }
    };

    return {
      i18nTxt,
      section_2,
      section_2_1,
      section_2_tmp,
      scrollEventHandler
    };
  }
});

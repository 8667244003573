















import { defineComponent, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';
import { isPC } from '@/utils/browser-state';

export default defineComponent({
  name: 'SectionTrend',
  props: {
    topHeight: Number
  },
  setup(props) {
    const section_5: Ref<HTMLDivElement | null> = ref(null);

    const scrollEventHandler = () => {
      if (isPC()) {
        if (
          window.pageYOffset >
          props.topHeight! +
            section_5.value!.offsetHeight * 8 -
            section_5.value!.offsetHeight / 2
        ) {
          section_5.value!.className = 'cont cont5 active5';
        }
      } else {
        if (
          window.pageYOffset >
          props.topHeight! + section_5.value!.offsetHeight * 8
        ) {
          section_5.value!.className = 'cont cont5 active5';
        }
      }
    };

    return {
      i18nTxt,
      section_5,
      scrollEventHandler
    };
  }
});

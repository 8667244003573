





































import { defineComponent, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';
import { isPC } from '@/utils/browser-state';

export default defineComponent({
  name: 'SectionPartner',
  props: {
    topHeight: Number
  },
  setup(props) {
    const section_6: Ref<HTMLDivElement | null> = ref(null);
    const isPCCheck: boolean = isPC();

    const scrollEventHandler = () => {
      if (isPCCheck) {
        if (
          window.pageYOffset >
          props.topHeight! +
            section_6.value!.offsetHeight * 9 -
            section_6.value!.offsetHeight / 2
        ) {
          section_6.value!.className = 'cont cont6 active6';
        }
      } else {
        if (
          window.pageYOffset >
          props.topHeight! +
            window.innerHeight * 9 -
            (window.innerHeight / 3) * 2
        ) {
          section_6.value!.className = 'cont cont6 active6';
        }
      }
    };

    return {
      i18nTxt,
      section_6,
      scrollEventHandler
    };
  }
});
